<template>
  <div
    class="modal card"
    style="z-index: 1006 !important"
    v-bind:class="{ 'is-active': showFSASelector }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body notification">
        <button class="delete" @click="closeModal">close</button>
        <h3 class="title is-6 has-text-success">Change Province</h3>
        <div class="level">
          <div class="level-left">
            <div class="select is-small is-rounded mx-3">
              <select
                style="width: 120px"
                v-model="selectedFSAProvince"
                :disabled="retrievingFSA"
              >
                <option
                  v-for="option in regionsExportList"
                  :selected="option == selectedFSAProvince"
                  :key="option"
                  :value="option"
                >
                  {{ regionToName[option] }}
                </option>
              </select>
            </div>
          </div>
          <button
            class="button level-item is-success is-small"
            @click="selectFSAProvince"
          >
            Confirm Province
          </button>
        </div>
      </section>
      <footer></footer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedFSAProvince: "AB",
      regionsExportList: [
        "AB",
        "BC",
        "MB",
        "NB",
        "NS",
        "ON",
        "PE",
        "QC",
        "SK",
        "NL",
      ],
      regionToName: {
        AB: "Alberta",
        BC: "British Columbia",
        MB: "Manitoba",
        NB: "New Brunswick",
        NL: "Newfoundland and Labrador",
        NS: "Nova Scotia",
        NT: "Northwest Territories",
        NU: "Nunavut",
        ON: "Ontario",
        PE: "Prince Edward Island",
        QC: "Quebec",
        SK: "Saskatchewan",
        YT: "Yukon",
      },
    };
  },
  props: {
    retrievingFSA: {
      type: Boolean,
      default: false,
    },
    showFSASelector: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async selectFSAProvince() {
      this.$emit("selectFSAProvince", this.selectedFSAProvince);
    },
  },
};
</script>
